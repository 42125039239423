@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  font-weight: 200;
}

code {
  font-family: 'Manrope', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

header {
  height: 60px;
  width: 100vw;
  background-color: white;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
}

h1 {
  font-size: 30px;
  font-weight: 200;
  margin-bottom: 100px;
}

header > div > h2 {
  font-weight: 200;
  margin-bottom: 0px;
}

h2 {
  font-weight: 200;
  margin-bottom: 50px;
}

header > div > h2 {
  font-weight: 200;
}

.menu {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu > div > a {
  margin-right: 20px;
  font-weight: 200;
  text-decoration: none;
  color: #000;
}

.page {
  padding-top: 80px;
}

.page-project-detail {
  padding-top: 200px;
  padding-bottom: 200px;
}

.container-home {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-home {
  font-size: 18px;
  font-weight: 500;
  width: 50%;
}

footer {
  min-height: 100px;
  background-color: white;
  padding-left: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 15px;
  font-weight: 200;
}

.page {
  padding-top: 110px;
  padding-bottom: 30px;
}

.loader {
  height: 400px;
  font-size: 30px;
  text-align: center;
}

label {
  font-size: 22px;
}

.menu-open {
  width: 400px;
}

.menu-open > ul {
  list-style: none;
}

.menu-open > ul > li {
  border-bottom: 1px solid #ccc;
}

.title-h2 {
  text-decoration: none;
  color: #000;
}

.title-h2:hover {
  text-decoration: none;
  color: #000;
}

.title-h2 > h2 {
  margin-bottom: 0px;
}

.image {
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.project {
  position: relative;
}

.project:hover .image {
  opacity: 0.3;
}

.project:hover .middle {
  opacity: 1;
}

.text {
  /* background-color: #ccc; */
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}

.filter-cat-active {
  text-decoration: underline;
  /* font-weight: bold; */
}

.no-pointer {
  pointer-events: none;
}

.stupid-isotope > * {
  transform: translateX(-10px);
}

.menu-mobile {
  display: none;
}

.no-link {
  color: #000;
  text-decoration: none;
}

.no-link:hover {
  text-decoration: underline;
  color: #000;
}

.img-detail-project {
  width: 600px;
}

.go-back > h3 > i {
  position: fixed;
  color: #000;
  left: 20px;
  top: 80px;
  z-index: 20;
  cursor: pointer;
}

.font-weight-400 {
  font-weight: 400;
}

h5 {
  font-weight: 200;
}

@media only screen and (min-width: 150px) and (max-width: 700px) {
  .menu {
    display: none;
  }

  .go-back > div > i {
    position: fixed;
    color: #000;
    left: 10px;
    top: 80px;
    z-index: 20;
    cursor: pointer;
  }

  .menu-mobile {
    display: block;
  }

  .menu-mobile-open{
    position: fixed;
    top: 60px;
    left: 0px;
    right: 0px;
    padding-left: 16px;
    background-color: #FFF;
    border-bottom: #000;
    font-size: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
  }

  .img-detail-project {
    width: 350px;
  }

  
  .menu-mobile-open > div > a {
    margin-right: 20px;
    font-weight: 200;
    text-decoration: none;
    color: #000;
    width: 100%;
  }

  .menu-mobile-open > div {
    margin-bottom: 10px;
  }

}
